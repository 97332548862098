"use client"

import { useEffect } from "react"
import { useLogger } from "next-axiom"
import { v4 as uuidv4 } from "uuid"
import { Button } from "@/components/ui/button"

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string }
  reset: () => void
}) {
  const log = useLogger()
  // Generate a unique identifier for the error
  const uid = uuidv4()

  useEffect(() => {
    log.error("Error captured in boundary", {
      uid: uid,
      error: error.message,
      stack: error.stack,
    })
  }, [error, log, uid])
  return (
    <div className="flex min-h-screen flex-col items-center justify-center gap-6 bg-gray-100 p-6 text-center text-gray-800">
      <h1 className="mb-4 text-4xl font-bold">Something went wrong!</h1>
      {/* <p className="mb-2 text-lg">{error.message}</p> */}
      <p className="flex flex-col items-center gap-5">
        Please use the following error ID when reporting this issue:{" "}
        <strong className="text-red-600">{uid}</strong>
      </p>
      <Button
        onClick={reset}
        className="rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-700"
      >
        Try again
      </Button>
    </div>
  )
}
